import * as React from 'react';
import {Container} from 'reactstrap';
import Image from "./Image";
import ReactLoading from 'react-loading';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

interface IState {
    images: any;
    lightboxIsOpen: boolean;
    lightboxPhotoIndex: number;
    lightboxPhotos: string[];
}

class Images extends React.Component<{}, IState> {

    constructor(props: any) {
        super(props);
        this.state = {
            images: null,
            lightboxIsOpen: false,
            lightboxPhotoIndex: 0,
            lightboxPhotos: []
        };
    }

    public componentDidMount() {
        this.getImages();
    }

    public render() {
        return (
            <div>
                {!this.state.images &&
                <ReactLoading type={'bars'} color={'#ffffff'} height={230} width={130}
                              className={'react-loading mt-2'}/>
                }
                <Container fluid={true} className={'card-columns'}>
                    {this.state.images &&
                    this.state.images.map((image: any, idx: number) => {
                        if (image.data.post_hint === 'image') {
                            return <Image data={image.data} key={idx} lightboxPhotoIndex={idx}
                                          openImageInLightbox={this.openImageInLightbox}/>;
                        } else {
                            return;
                        }
                    })}
                </Container>
                {this.state.lightboxIsOpen &&
                <Lightbox mainSrc={this.state.lightboxPhotos[this.state.lightboxPhotoIndex]}
                          nextSrc={this.state.lightboxPhotos[(this.state.lightboxPhotoIndex + 1) % this.state.lightboxPhotos.length]}
                          prevSrc={this.state.lightboxPhotos[(this.state.lightboxPhotoIndex + this.state.lightboxPhotos.length - 1) % this.state.lightboxPhotos.length]}
                    // tslint:disable-next-line jsx-no-lambda
                          onCloseRequest={() => this.setState({lightboxIsOpen: false})}
                    // tslint:disable-next-line jsx-no-lambda
                          onMovePrevRequest={() =>
                              this.setState({
                                  lightboxPhotoIndex: (this.state.lightboxPhotoIndex + this.state.lightboxPhotos.length - 1) % this.state.lightboxPhotos.length,
                              })
                          }
                    // tslint:disable-next-line jsx-no-lambda
                          onMoveNextRequest={() =>
                              this.setState({
                                  lightboxPhotoIndex: (this.state.lightboxPhotoIndex + 1) % this.state.lightboxPhotos.length,
                              })
                          }
                />}
            </div>
        );
    }

    private openImageInLightbox = (index: number) => {
        this.setState({lightboxPhotoIndex: index, lightboxIsOpen: true})
    }

    private getImages() {
        fetch(
            'https://monzter.net/json.php',
            {
                headers: new Headers({
                    'Content-Type': 'application/json',
                }),
                method: 'GET'
            }).then((response: Response) => {
            console.log(response);
            return response.json();
        }).then((json: any) => {
            console.log('json', json);
            if (json.data !== undefined && json.data.children !== undefined) {
                const lightboxPhotos = json.data.children.map((image: any) => {
                        if (image.data.post_hint === 'image') {
                            return image.data.preview.images[0].source.url.replace(/&amp;/g, '&')
                        }
                    }
                );
                this.setState({
                    lightboxPhotos
                });

                this.setState({images: json.data.children});
            }
        }).catch((ex: JSON) => {
            console.log('parsing failed', ex);
        });
    }
}

export default Images;
