import * as React from 'react';
import {Navbar, NavbarBrand} from 'reactstrap';

class Header extends React.Component {
    public render() {
        return (
            <Navbar color="dark" dark={true} className={'mb-2'}>
                <NavbarBrand href="/">Monzter.net</NavbarBrand>
            </Navbar>
        );
    }
}

export default Header;
