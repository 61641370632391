import * as React from 'react';
import {Card, CardBody, CardImg, CardTitle} from 'reactstrap';

type OpenImageInLightbox = (index: number) => any;

interface IProps {
    data: any;
    lightboxPhotoIndex: number;
    openImageInLightbox: OpenImageInLightbox;
}

class Image extends React.Component<IProps, {}> {

    constructor(props: IProps) {
        super(props);
    }

    public render() {
        if (this.props.data.post_hint !== undefined && this.props.data.post_hint === 'image') {
            const images = this.props.data.preview.images[0];
            let smallImage = images.resolutions[1].url;
            if (images.resolutions[3] !== undefined) {
                smallImage = images.resolutions[3].url;
            } else if (images.resolutions[2] !== undefined) {
                smallImage = images.resolutions[2].url;
            }
            return (
                <Card className={'card-image'}>
                    <CardImg top={true} width="100%"
                             src={smallImage.replace(/&amp;/g, '&')}
                        // tslint:disable-next-line jsx-no-lambda
                             onClick={() => this.props.openImageInLightbox(this.props.lightboxPhotoIndex)}/>
                    <CardBody>
                        <a href={this.props.data.preview.images[0].source.url.replace(/&amp;/g, '&')}>
                            <CardTitle>{this.props.data.title}</CardTitle>
                        </a>
                    </CardBody>
                </Card>
            );
        } else {
            return (
                <React.Fragment/>
            );
        }
    }
}

export default Image;
