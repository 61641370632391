import * as React from 'react';
import Images from "./Images";
import Header from "./Header";
import * as ReactGA from 'react-ga'

ReactGA.initialize('UA-3998799-1');
ReactGA.pageview("/");

class App extends React.Component {
    public render() {
        return (
            <div className="App">
                <Header/>
                <Images/>
            </div>
        );
    }
}

export default App;
